<template>
    <div class="card px-4">
        <h5 class="mb-5 font-medium">Transaksi</h5>
        <div class="grid align-items-center mb-5">
            <div class="col-12 sm:col-6 lg:mb-0 pb-0">
                <form class="grid formgrid" @submit.prevent="getData()">
                    <div class="col-9 mb-3 lg:mb-0">
                        <span class="p-input-icon-right w-full">
                            <InputText type="text" placeholder="Cari disini" class="w-full" v-model="keyword" />
                            <i class="pi pi-search" />
                        </span>
                    </div>
                    <div class="col-2 mb-3 lg:mb-0">
                        <Button label="" type="submit" icon="pi pi-filter-fill" class="btn-white px-2">
                            <icon-enter></icon-enter>
                        </Button>
                    </div>
                </form>
            </div>
        </div>

        <table-list :data="data" :columns="columns"
            :loading="loading"
            :offset="offset"
            :limit="limit"
            @rowClick="rowClick"
            :is_cannot_edit="true"
            :is_cannot_delete="true"
            @callbackPaginate="currentPage">
        </table-list>
    </div>
</template>

<script>
import TableList from '@/components/Table'
import IconEnter from '@/components/IconEnter'

export default {
    components: {
        TableList, IconEnter,
    },
    data() {
        return {
            moment: require('moment-timezone'),
            loading: false,
            limit: 10,
            offset: 0,
            keyword: '',
            data: [],
            columns: [
                {
                    field: 'trx_date', header: 'Tanggal Transaksi', sortable: true,
                    process: (data) => this.moment(data).format('DD-MM-YYYY HH:mm'),
                },
                { field: 'type', header: 'Tipe', sortable: true },
                { field: 'title', header: 'Transaksi', sortable: true, position: 'left' },
                { field: 'category', header: 'Kategori', sortable: true },
                { field: 'cust_name', header: 'Customer Name', sortable: true, position: 'left' },
                { field: 'customer_sof_name', header: 'Customer SoF', sortable: true, position: 'left' },
                {
                    field: 'amount', header: 'Amount', sortable: true,
                    process: (data) => this.formatCurency(data),
                },
                {
                    field: 'fee', header: 'Fee', sortable: true,
                    process: (data) => this.formatCurency(data),
                },
                { 
                    field: 'status',
                    header: 'Status',
                    sortable: true,
                    process: (data) => this.status[data],
                },
            ],
            status: {
                0: { label: 'Pending', type: 'tag', class: 'mr-2 py-1 text-10 border-round-lg bg-linear-secondary' },
                1: { label: 'Success', type: 'tag', class: 'mr-2 py-1 text-10 border-round-lg bg-linear-green' },
                2: { label: 'Cancel', type: 'tag', class: 'mr-2 py-1 text-10 border-round-lg bg-linear-red' },
            },
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.loading = true
            this.$http
                .get(`${process.env.VUE_APP_API_URL}/transaction?global_filter=${this.keyword}`)
                .then(response => {
                    this.loading = false
                    this.data = response.data.data.transactions
                })
        },
        currentPage(event) {
            this.limit = event.rows
            this.offset = event.page * this.limit
        },
        rowClick(event) {
            this.$router.push({name: 'transaction-detail', params: {id: event.data.id}})
        },
        formatCurency(data) {
            let value = parseInt(data)
            return value.toLocaleString('id-ID', {style: 'currency', currency: 'IDR', minimumFractionDigits: 0})
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
